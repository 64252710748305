import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../shared/services/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from '@angular/router';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { ForgetPasswordValidation } from '../../shared/validations/forget-password.validation';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

  form: FormGroup;

  public errorMessage: string = ""

  public hideForm: boolean = false
  showPassword = {
    new: false,
    confirm: false
  };
  loader: boolean = false;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private route: ActivatedRoute, private googleAnalytics: GoogleAnalyticsService) {
    this.form = ForgetPasswordValidation.createForgetPasswordForm(this.formBuilder)
  }

  togglePasswordVisibility(field: 'new' | 'confirm', event: Event) {
    event.preventDefault();
    this.showPassword[field] = !this.showPassword[field];
  }

  savePassword() {
    this.errorMessage = ''
    this.form.markAllAsTouched()
    if (this.form.invalid) return

    this.spinner.show();
    this.loader = true;

    let {email, new_password, confirm_new_password} = this.form.value

    const token = this.route.snapshot.params['token']

    this.authService.resetPassword({email, new_password, confirm_new_password}, token).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.loader = false;

        if (res.status === 200) {
          this.hideForm = true;
        }
      },
      error => {
        this.spinner.hide();
        this.loader = false;
        this.errorMessage = error.error.errors
      }
    );
  }

  trackButtonClick(btn_name: any) {
    this.form.markAllAsTouched()
    if (this.form.invalid) return
    this.googleAnalytics.trackButtonClick({'button': btn_name, page: 'Forget Password'});
  }
}
