<div class="wrap-auth">
  <div class="row h-100">
    <div class="col-lg-5 p-0">
      <app-layout bgImage="../../assets/images/login-bg.png"></app-layout>
    </div>
    <div class="col-lg-7 p-0 col-12 ">
      <div class="right-section">
        <div class="right-section-content">
          <h3 class="title">Member login</h3>
          <ngb-alert class="auth-alert" type="danger" *ngIf="errorMessage"  (closed)="clearErrorMessage()">
            <div class="d-flex align-items-center"><span class="me-2"><img width="32"
                                                                           src="../../../assets/images/invalid-icon.svg"
                                                                           alt="">
          </span> {{errorMessage}}</div>
          </ngb-alert>
          <div class="onboarding-card">

            <form [formGroup]="loginForm" action="#" class="onboarding-form needs-validation" novalidate>
              <div>
                <div class="mb-lg-4 mb-3 has-validation">
                  <label for="exampleInputEmail1" class="form-label">E-mail</label>

                  <input type="email" maxlength="255" formControlName="email" class="form-control"
                         [ngClass]="{ 'is-invalid': (loginForm.get('email')?.touched && loginForm.get('email')?.errors) }"
                         placeholder="Please enter your e-mail"
                         id="exampleInputEmail1" aria-describedby="emailHelp" required>


                  <div class="invalid-feedback" *ngIf="loginForm.get('email')?.hasError('email') && !loginForm.get('email')?.hasError('invalidEmail')">
                    Invalid e-mail.
                  </div>

                  <div class="invalid-feedback" *ngIf="loginForm.get('email')?.hasError('required')">
                    E-mail is required
                  </div>

                  <div class="invalid-feedback"
                       *ngIf="loginForm.get('email')?.hasError('invalidEmail')  && loginForm.get('email')?.touched">
                    Please enter a correct e-mail.
                  </div>

                </div>
                <div class="mb-3 has-validation">
                  <label for="exampleInputPassword1" class="form-label">Password</label>
                  <div class="password-wrap">
                    <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password"
                           placeholder="Password" class="form-control"
                           [ngClass]="{ 'is-invalid': (loginForm.get('password')?.touched && loginForm.get('password')?.errors)  }"
                           id="exampleInputPassword1" required>
                    <a class="show-password active" href="#"
                       (click)="togglePasswordVisibility($event)">{{showPassword ? 'Hide' : 'Show'}}</a>

                    <div class="invalid-feedback"
                         *ngIf="loginForm.get('password')?.hasError('apiError') && loginForm.get('password')?.touched">
                      {{errorMessage}}
                    </div>

                    <div class="invalid-feedback"
                         *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched">
                      Password is required
                    </div>
                  </div>
                </div>
                <div class="mb-4 d-flex justify-content-between">
<!--                  <div class="form-check">-->
<!--                    <input type="checkbox" class="form-check-input" id="exampleCheck1">-->
<!--                    <label class="form-check-label" for="exampleCheck1">Keep me logged in</label>-->
<!--                  </div>-->
                  <a href="" routerLink="/reset/password" class="forgot-password" (click)="trackButtonClick('Forgot password')">Forgot password?</a>
                </div>
                <button type="submit" class="btn btn-auth btn-primary w-100" (click)="login(); trackButtonClick('Login')">
                  <app-spinner></app-spinner>
                  <span *ngIf="!loader">  Login </span>
                </button>


              </div>
            </form>
          </div>
          <p class="text mb-lg-4 mb-3">Don't have an account yet? <a href="" routerLink="/register" class="join" (click)="trackButtonClick('Register')">Join
            for free</a></p>
          <p class="text ">This website is for members of the veterinary professions and related industries only.</p>
        </div>
      </div>
    </div>
  </div>
</div>
