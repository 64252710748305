import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidator {
  static requiredField(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value ? null : {required: message};
    };
  }

  static alphaNumeric(): ValidatorFn {
    return (control: AbstractControl) => {
      const regex = /^[a-zA-Z0-9 ]*$/;
      return regex.test(control.value) ? null : {invalidAlphaNumeric: true};
    };
  }

  static emailField(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(control.value) ? null : {invalidEmail: true};
    };
  }

  static nameField(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;
      const regex = /^(?!.*--)(?!.*'')(?!.*-.*-)(?!.*'.*')[a-zA-Z-' ]+$/;
      return regex.test(control.value) ? null : {invalidName: true};
    };
  }


  static matchValidator(matchTo: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const parent = control.parent as FormGroup | null;
      const matchingControl = parent?.get(matchTo);
      if (matchingControl && control.value !== matchingControl.value) {
        return {'mismatch': true};
      }
      return null;
    };
  }
}
