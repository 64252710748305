import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from "../validators/form.validator";

export class RegisterValidation {

  static createRegisterForm(fb: FormBuilder): FormGroup {
    return fb.group({
      name: ['', [FormValidator.requiredField('Name is required.'), FormValidator.nameField()]],
      last_name: ['', [FormValidator.requiredField('Last name is required.'), FormValidator.nameField()]],
      email: ['', [FormValidator.requiredField('E-mail is required.'), Validators.email, FormValidator.emailField()]],
      password: ['', [FormValidator.requiredField('Password is required.'), Validators.minLength(8)]],
      job_role: ['C', FormValidator.requiredField('')],
      country_of_residence: ['', FormValidator.requiredField('Country of residence is required.')],
      rcvs: ['', [Validators.minLength(7), Validators.maxLength(7), Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      agreeTerms: [false]
    });
  }

  static rcvsValidation(form: any, countries: any[]) {
    const rcvsControl = form.get('rcvs');
    const role = form.value['job_role'];
    const country = countries.find((c: any) => c.country_name === form.get('country_of_residence')?.value);
    const isGB = country?.country_code?.startsWith('GB');
    let fieldName = '';
    let showField = true;

    const setValidators = (name: string, validators: any[]) => {
      fieldName = name;
      rcvsControl.setValidators(validators);
    };

    if (['C', 'D', 'G'].includes(role)) {
      const baseValidators = [Validators.pattern(/^[a-zA-Z0-9]+$/)];
      isGB
        ? setValidators('RCVS number', [...baseValidators, Validators.minLength(7), Validators.maxLength(7)])
        : setValidators('Membership number', baseValidators);
    } else {
      rcvsControl.clearValidators();
      showField = false;
    }

    rcvsControl.updateValueAndValidity();
    return { fieldName, showField };
  }
}
