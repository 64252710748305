import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from "../validators/form.validator";

export class ForgetPasswordValidation {

  static createForgetPasswordForm(fb: FormBuilder): FormGroup {
    return fb.group({
      email: ['', [FormValidator.requiredField('E-mail is required'), Validators.email, FormValidator.emailField()]],
      new_password: ['', [FormValidator.requiredField('Password is required'), Validators.minLength(8)]],
      confirm_new_password: ['', [FormValidator.requiredField('Confirm new password is required'), FormValidator.matchValidator('new_password')]]
    });
  }
}
