import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from "../validators/form.validator";

export class ResetPasswordValidation {

  static createResetPasswordForm(fb: FormBuilder): FormGroup {
    return fb.group({
      email: ['', [FormValidator.requiredField('E-mail is required.'), Validators.email, FormValidator.emailField()]]
    });
  }
}
