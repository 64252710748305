export function formatDateWithTime(value: any): string {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString();
  }
  return '';
}

export function subscriptionsField(subscription: string): string {
  let subscriptions: any = {
    weekly_vt_letter: 'Vet Times Weekly Newsletter',
    daily_vt_letter: 'Vet Times Daily Digest',
    monthly_vnt_letter: 'VN Times Monthly Newsletter',
    vbd_updates: 'VBD Updates',
    industry_updates: 'Industry Updates',
    print_vettimes: 'Vet Times',
    print_vntimes: 'VN Times',
    print_vbj: 'VBJ',
    weekly_job_letter: "Weekly",
    monthly_advice: "Monthly",
    subscribe_to_vbd_offers: "Subscribe to VBD Offers",
    subscribe_to_third_party_info: "Subscribe to third party info",
    subscribe_to_workplace_panel: "Subscribe to workplace panel"
  };

  return subscriptions[subscription];
}

export const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export function areJSONEqual(json1: JSON, json2: JSON) {
  return JSON.stringify(json1) === JSON.stringify(json2);
}

export function rcvsInput(event: Event, form: any) {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  let validValue = value.charAt(0).replace(/[^a-zA-Z0-9]/, '');
  validValue += value.slice(1, 6).replace(/[^0-9]/g, '');

  if (value.length > 6) {
    validValue += value.charAt(6).replace(/[^a-zA-Z0-9]/, '');
  }

  validValue = validValue.slice(0, 7);

  if (value !== validValue) {
    form.get('rcvs')?.setValue(validValue, { emitEvent: false });
  }
}

export function membershipInput(event: Event, form: any) {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  let validValue = value.charAt(0).replace(/[^a-zA-Z0-9]/, '');

  validValue += value.slice(1).replace(/[^0-9]/g, '');

  if (value !== validValue) {
    form.get('rcvs')?.setValue(validValue, { emitEvent: false });
  }
}


export function getCountryName(data: any) {
  switch (data.country_name) {
    case 'United Kingdom of Great Britain and Northern Ireland':
      return data.region_name
    case 'United States of America':
      return 'United States'
    case 'Moldova (the Republic of)':
      return 'Moldova, Republic Of'
    case 'Bolivia (Plurinational State of)':
      return 'Bolivia'
    case 'Venezuela (Bolivarian Republic of)':
      return 'Venezuela'
    case 'North Macedonia':
      return 'Macedonia, Former Yugoslav Republic Of'
    default:
      return data.country_name
  }
}

export function trimFormValues(form: any) {
  Object.keys(form.controls).forEach(key => {
    const control = form.get(key);
    if (control && control.value && typeof control.value === 'string') {
      control.setValue(control.value.trim(), {emitEvent: false});
    }
  });
}

export function removeEmptyValues(payload: any) {
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (payload[key] === null || payload[key] === undefined || payload[key] === "") {
        delete payload[key];
      }
    }
  }
  return payload;
}
