import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {LocationService} from '../../shared/services/location.service';
import {CookieService} from "ngx-cookie-service";
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { RegisterValidation } from '../../shared/validations/register.validation';
import { rcvsInput, membershipInput, getCountryName } from '../../helpers/my_helper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {

  form!: FormGroup;
  curr_step: number | undefined;
  token: string | null = null;
  showPassword: boolean = false;
  showRcvs: boolean = true;
  errorMessage: any = {};
  loader: boolean = false;
  showResendLink: boolean = false;
  countries: any = [];
  rcvsFieldsName: string = '';
  isDisabled: boolean = true;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private locationService: LocationService, private cookieService: CookieService, private googleAnalytics: GoogleAnalyticsService) {
    this.form = RegisterValidation.createRegisterForm(this.formBuilder)
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      params['token'] ? this.emailVerification(params['token']) : this.curr_step = 1;
    });
    this.getCountries();
  }

  setCurrentLocation() {

    this.locationService.getIpAddress().subscribe(data => {

      const ipAddress = data.ip

      this.locationService.getLocation(ipAddress).subscribe(res => {

        const country_name = getCountryName(res.data)

        if (country_name) {
          const country = this.countries.find((c: any) => c.country_name.toLowerCase() == country_name.toLowerCase())

          if (country) {
            this.form.patchValue({country_of_residence: country?.country_name});
            this.onCountryChange();
          }

        }
      }, error => {
        console.error('Error fetching location', error);
      })
    }, error => {
      console.error('Error fetching IP address', error);
    })
  }

  trackButtonClick(event: any) {
    const controls = ['name', 'last_name', 'email', 'password'].map(field => this.form.get(field));

    if (event.button === 'Create account') {

      event.payload = this.getBody()
      delete event.payload.password;

      this.form.markAllAsTouched();
      if (this.form.invalid) return;

    } else if (event.button === 'Next' && controls.some(control => control?.invalid)) return;

    this.googleAnalytics.trackButtonClick(event);
  }

  getCountries() {
    this.apiService.httpGetMyAccount('user/countries').subscribe(
      (res: any) => {
        this.countries = res.data;
        this.setCurrentLocation();
      },
      (err: any) => {
        console.error('Error fetching countries', err);
      }
    );
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    this.showPassword = !this.showPassword;
  }

  next() {
    const controls = ['name', 'last_name', 'email', 'password'].map(field => this.form.get(field));
    controls.forEach(control => control?.markAsTouched());
    if (controls.some(control => control?.invalid)) return;
    this.checkEmail();
  }

  checkEmail() {
    this.spinner.show();
    this.loader = true;
    const {email} = this.form.value;
    this.apiService.httpPost('auth/check-email', {email}).subscribe(
      (res: any) => {
        this.curr_step = 2;
        this.spinner.hide();
        this.loader = false;
        this.errorMessage.email = '';
      },
      (err) => {
        this.errorMessage.email = err.error.errors.email;
        this.spinner.hide();
        this.loader = false;
        this.showResendLink =
          err.error.errors.email === 'Email is already registered'
            ? false
            : true;
      }
    );
  }

  selectJobRole(e: MouseEvent, role: any): void {
    e.preventDefault();
    this.form.patchValue({job_role: role});

    ({ fieldName: this.rcvsFieldsName, showField: this.showRcvs } = RegisterValidation.rcvsValidation(this.form, this.countries))

    this.isDisable();
  }

  onCountryChange() {
    this.form.patchValue({ rcvs: "" });
    ({ fieldName: this.rcvsFieldsName, showField: this.showRcvs } = RegisterValidation.rcvsValidation(this.form, this.countries))
  }

  findRcvsNumber(e: any): void {
    e.preventDefault();
    window.open(environment.rcvsUrl, '_blank');
  }

  validateRcvsInput(event: Event) {
    this.rcvsFieldsName == 'RCVS number' ? rcvsInput(event, this.form) : membershipInput(event, this.form)
  }

  isDisable() {
    this.isDisabled = this.form.invalid || !this.form.value['agreeTerms'] ? true : false;
  }

  getBody() {
    const { password, rcvs } = this.form.value;
    this.form.patchValue({ password: password.trim() });

    if (this.rcvsFieldsName === 'Membership number') {
      this.form.addControl('membership', new FormControl(''))
      this.form.patchValue({membership: rcvs})
    }

    if (!this.showRcvs || this.rcvsFieldsName === 'Membership number' || !rcvs) {
      delete this.form.value['rcvs'];
    }

    return this.form.value;
  }

  register() {
    this.spinner.show();
    this.loader = true;
    this.apiService.httpPost('auth/register', this.getBody()).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.loader = false;
        this.curr_step = 3;
      },
      (err: any) => {
        this.errorMessage.rcvs = err.error.errors.rcvs;
        this.spinner.hide();
        this.loader = false;
      }
    );
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.register();
  }

  resendVerificationEmail(e: any) {
    e.preventDefault();
    this.spinner.show();
    this.loader = true;
    const {email} = this.form.value;
    this.apiService
      .httpPost('auth/resend-email', {email})
      .subscribe(
        (res: any) => {
        this.toastr.success(res.message);
        this.spinner.hide();
        this.loader = false;
      }, (error: any) => {
        this.toastr.error(error.error.errors);
        this.spinner.hide();
        this.loader = false;
      }
    );
  }

  emailVerification(token: any) {
    this.apiService.httpGet(`auth/verify-email?token=${token}`).subscribe(
      (res: any) => {

        let user = res.data.user;

        localStorage.setItem('user', JSON.stringify(user))
        this.cookieService.set('sso_signed', res.data.token, 0, '/', '.vettimes.co.uk');
        this.spinner.hide();
        this.loader = false;
        this.curr_step = 5;
      },
      (err) => {
        this.curr_step = 4;
        this.spinner.hide();
        this.loader = false;
      }
    );
  }

  productLink() {
    window.open(environment.myJobsUrl);
  }
}
